<template>
    <section id="contact-us" class="contact-us">
        <h2 v-if="!props.showTitleIcon" class="mb-6">{{ props.title }}</h2>
        <PageTitle v-if="props.showTitleIcon" :title="props.title" class="pb-4" icon="messages" />
        <n-spin v-if="isSubmitted && isIdle" size="small" />
        <n-alert v-if="isSubmitted && isSuccess" :title="$t('contactRequestSuccessTitle')" class="mb-8" closable type="success" @close="onMessageClose">
            {{ $t('contactRequestSuccessDescription') }}
        </n-alert>
        <n-form v-if="!isSubmitted || shouldKeepForm" ref="formRef" :model="formData" :rules="validationRules">
            <div :class="`grid gap-8 ${columns.length === 1 ? 'tablet:flex flex-col items-center' : 'tablet:grid-cols-2'}`">
                <div v-for="col in columns" :key="col.key" :class="columns.length === 1 ? 'gap-y-3 tablet:gap-x-8 tablet:w-1/2' : col.class">
                    <template v-if="col.items.length">
                        <div v-for="item in col.items" :key="item._uid" :class="item.className">
                            <template v-if="item.type === 'upload'">
                                <upload
                                    :key="item.name"
                                    :formData="formData"
                                    :path="item.name"
                                    :placeholder="item.placeholder"
                                    :show-label="columns.length === 1"
                                    :title="item.title"
                                    :value="formData[item.name]"
                                    @input="updateData(formData, $event, item.name)"
                                />
                            </template>
                            <template v-else-if="item.type === 'select'">
                                <select-list
                                    :key="item.name"
                                    :options="getOptions(item.name, JSON.parse(item.options))"
                                    :path="item.name"
                                    :required="item.required"
                                    :title="item.title"
                                    :value="formData[item.name]"
                                    @input="updateData(formData, $event, item.name)"
                                />
                            </template>
                            <template v-else-if="item.type === 'checkbox'">
                                <n-form-item :path="item.name">
                                    <n-checkbox :key="item.name" v-model:checked="formData[item.name]" :path="item.name">
                                        <label class="block text-2xs" v-html="item.title" />
                                    </n-checkbox>
                                </n-form-item>
                            </template>
                            <template v-else-if="item.type === 'address'">
                                <n-form-item :key="item.name" :label="item.title" :path="item.name">
                                    <address-autocomplete v-model="formData[item.name]" :placeholder="item.placeholder" required />
                                </n-form-item>
                            </template>
                            <template v-else-if="item.type === 'address-select'">
                                <n-form-item :key="item.name" :label="item.title" :path="item.name">
                                    <investment-select class="w-full" hide-title show-single />
                                </n-form-item>
                            </template>
                            <template v-else-if="item.type === 'textarea'">
                                <n-form-item :key="item.name" :label="item.title" :path="item.name">
                                    <n-input
                                        :id="item._uid"
                                        v-model:value="formData[item.name]"
                                        :autosize="{
                                            minRows: 5,
                                            maxRows: 5,
                                        }"
                                        :path="item.name"
                                        :placeholder="item.placeholder"
                                        :type="item.type"
                                    ></n-input>
                                </n-form-item>
                            </template>
                            <template v-else>
                                <n-form-item :key="item.name" :label="item.title" :path="item.name">
                                    <n-input
                                        :id="item._uid"
                                        v-model:value="formData[item.name]"
                                        :path="item.name"
                                        :placeholder="item.placeholder"
                                        :type="item.type"
                                    ></n-input>
                                </n-form-item>
                            </template>
                        </div>
                        <n-button
                            v-if="col.key === 'col2'"
                            :loading="isLoading"
                            block
                            size="large"
                            type="primary"
                            @click="onSubmit"
                            @keyup.enter="onSubmit"
                            v-id="'submit-contact-form'"
                        >
                            {{ buttonText }}
                        </n-button>
                    </template>
                </div>
            </div>
        </n-form>
    </section>
</template>

<script lang="ts" setup>
import InvestmentSelect from '@/components/InvestmentSelect.vue';
import PageTitle from '@/components/PageTitle.vue';
import { useSendContactForm } from '@/hooks/contact.hooks';
import { addressValidator, combineValidators, emailValidator, existsValidator, isTrueValidator, phoneNumberValidator } from '@/lib/validators';
import { useConfigStore, useCurrentContactStore, useSelectedInvestmentStore } from '@/store';
import { addressFormatter } from '@condo/formatters';
import { contactTypesLabels } from '@condo/hubspot/lists';
import type { FormInst } from 'naive-ui';
import { Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import type { ContactRequestParams } from '../../../domain/schemas';
import AddressAutocomplete from '../autocomplete/AddressAutocomplete.vue';
import SelectList from './formElements/Select.vue';
import Upload from './formElements/Upload.vue';

const { meta } = useRoute();

interface Emits {
    (ev: 'update:formRef', formRef: Ref<FormInst>): void;

    (ev: 'update:formData', formData: Partial<ContactRequestParams>): void;
}

type InputElement = {
    _uid: string;
    name: string;
    type: 'textarea' | 'address' | 'address-select' | 'text' | 'number' | 'checkbox' | 'upload' | 'select' | 'email' | 'tel';
    title: string;
    options: string;
    required: boolean;
    placeholder: string;
    className: string;
};
type Props = {
    column1: InputElement[];
    column2: InputElement[];
    buttonText: string;
    title: string;
    showTitleIcon?: boolean;
};

defineEmits<Emits>();
const props = defineProps<Props>();
const { t } = useI18n();
const message = useMessage();
const contactStore = useCurrentContactStore();
const { config } = useConfigStore();

// @tailwind-safe-list row-span-2 col-span-2
const columns = computed(() => [
    ...(props.column1?.length
        ? [
              {
                  key: 'col1',
                  class: 'grid gap-y-3 tablet:grid-cols-2 tablet:gap-x-8',
                  items: props.column1,
              },
          ]
        : []),
    ...(props.column2?.length
        ? [
              {
                  key: 'col2',
                  class: 'grid gap-y-3 tablet:gap-x-8',
                  items: props.column2,
              },
          ]
        : []),
]);

const getFormInitialData = () => ({
    salutation: contactStore.contact?.salutation || '',
    customerType: (meta.customerType as string) || '',

    firstName: contactStore.contact?.firstname || '',
    lastName: contactStore.contact?.lastname || '',
    email: contactStore.contact?.email || '',
    phone: contactStore.contact?.phoneNumber || '',
    locationRef: undefined,

    message: '',
    personalDataConfirmation: false,
    attachments: '',
    address: { houseNumber: undefined, city: undefined, street: undefined, zipcode: undefined },
    condoUuid: '',
});
const formData = ref(getFormInitialData());
const formRef = ref<FormInst | null>(null);
const isSubmitted = ref(false);

const selectedInvestmentStore = useSelectedInvestmentStore();

const validationRules = {
    email: {
        required: true,
        validator: combineValidators(existsValidator(t), emailValidator(t)),
        trigger: ['blur'],
    },
    firstName: {
        required: true,
        validator: existsValidator(t),
        trigger: ['blur'],
    },
    lastName: {
        required: true,
        validator: existsValidator(t),
        trigger: ['blur'],
    },
    customerType: {
        required: true,
        validator: existsValidator(t),
        trigger: ['blur'],
    },
    salutation: {
        required: true,
        validator: existsValidator(t),
        trigger: ['select'],
    },
    locationRef: {
        required: false,
        validator: existsValidator(t),
        trigger: ['blur'],
    },
    phone: {
        required: true,
        trigger: ['blur'],
        validator: combineValidators(existsValidator(t), phoneNumberValidator(t)),
    },
    message: {
        required: true,
        validator: existsValidator(t),
        trigger: ['blur'],
    },
    address: {
        required: true,
        trigger: ['blur'],
        requiredFields: ['street', 'houseNumber', 'city', 'zipcode'],
        message: t('errors.invalidAddress'),
        validator: addressValidator,
    },
    personalDataConfirmation: {
        required: true,
        validator: isTrueValidator,
        message: t('errors.consentRequired'),
        trigger: ['blur'],
    },
};

const { isLoading, mutateAsync: sendData, isError, error, isSuccess, isIdle } = useSendContactForm();
const shouldKeepForm = computed(() => {
    return (isSubmitted.value && isError.value) || isLoading.value || isIdle.value;
});
const mapCategories = list => [{ key: '', value: t('selectDefaultValue') }].concat(list?.map(item => ({ key: item.key, value: item.value })) || []);

function updateData(formData, value: string, key: keyof typeof validationRules) {
    formData[key] = value;
    if (key === 'customerType') {
        updateData(formData, '', 'category');
    }
}

function onMessageClose() {
    formData.value = getFormInitialData();
    isSubmitted.value = false;
}

onMounted(() => {
    updateData(formData.value, selectedInvestmentStore?.investment?.estate?.location, 'address');
    updateData(formData.value, selectedInvestmentStore?.investment?.estate?.condoUuid, 'condoUuid');
});

watch(
    () => selectedInvestmentStore.investment,
    newSelectedInvestment => {
        updateData(formData.value, newSelectedInvestment?.estate?.location, 'address');
        updateData(formData.value, newSelectedInvestment?.estate?.condoUuid, 'condoUuid');
    },
);

function getErrorMessage() {
    switch (error?.value?.message) {
        case 'INVALID_RECAPTCHA_SCORE': {
            return t('errors.recaptchaInvalidScore');
        }
        case 'MISSING_RECAPTCHA_TOKEN':
        case 'INVALID_RECAPTCHA_TOKEN': {
            return t('errors.recaptchaMissing');
        }
        default: {
            return t('errors.tryAgain');
        }
    }
}

function getOptions(name, options) {
    let finalOptions: { key: string; value: string }[] = [];
    switch (name) {
        case 'customerType':
            finalOptions = Object.keys(contactTypesLabels).map(key => ({ key, value: contactTypesLabels[key] }));
            break;

        default:
            finalOptions = options;
            break;
    }

    if (name === 'salutation' && finalOptions && finalOptions[0]?.key !== '') {
        finalOptions.unshift({ key: '', value: t('selectDefaultValue') });
    }
    return finalOptions?.map(item => ({ label: item.value, value: item.key }));
}

const onSubmit = async () => {
    formRef.value?.validate(async errors => {
        if (!errors) {
            const {
                attachments,
                address: { street, houseNumber, city, zipcode },
                ...data
            } = formData.value;

            if (attachments && Array.isArray(attachments)) {
                const isAttachmentsError = attachments.some(item => item?.status !== 'finished');
                const isAttachmentSizeUnallowed = attachments.some(item => item?.reason === 'size');
                if (isAttachmentsError) {
                    if (isAttachmentSizeUnallowed) {
                        message.error(t('errors.fileSizeLimitExceeded'));
                        return;
                    }
                    message.error(t('errors.fileUpload'));
                    return;
                }
            }
            const address = addressFormatter({ street, houseNumber, city, zipcode });
            try {
                grecaptcha.enterprise.ready(async () => {
                    const token = await grecaptcha.enterprise.execute(config!.recaptchaId, { action: 'contact_form' });
                    try {
                        await sendData({
                            ...data,
                            recaptcha: token,
                            address,
                            attachments: Array.isArray(attachments) ? attachments.map(item => item?.id) : [],
                        });
                    } catch (e) {
                        message.error(getErrorMessage());
                    }
                });
                isSubmitted.value = true;
            } catch (e) {
                message.error(getErrorMessage());
            }
        }
    });
};
</script>

<style lang="scss" scoped>
.contact-us {
    @apply container py-12;

    @screen laptop {
        @apply py-24;
    }
}
</style>
