import type { ContactShortData } from '@condo/sdk/propx';
import { defineStore } from 'pinia';

export const useCurrentContactStore = defineStore('currentContact', () => {
    const contact = ref<ContactShortData | null>(null);
    function setContact(data: ContactShortData) {
        contact.value = data;
    }
    return { contact, setContact };
});
