<template>
    <h2 v-if="title">{{ title }}</h2>
    <div class="section-columns">
        <div class="column">
            <StoryblokComponent v-for="blok in column1" :key="blok._uid" :blok="blok" />
        </div>
        <div v-if="column2?.length" class="column">
            <StoryblokComponent v-for="blok in column2" :key="blok._uid" :blok="blok" />
        </div>
        <div v-if="column3?.length" class="column">
            <StoryblokComponent v-for="blok in column3" :key="blok._uid" :blok="blok" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { SbBlokData } from '@/components/StoryblokComponent.vue';

const props = defineProps<{ title?: string; column1: SbBlokData[]; column2?: SbBlokData[]; column3?: SbBlokData[] }>();
const columnsQuantity = [props.column1, props.column2, props.column3].reduce((total, column) => total + (column?.length ? 1 : 0), 0).toString();

useCssVars(() => ({
    columnsQuantity,
}));
</script>

<style lang="scss">
.section-columns {
    @apply flex
        flex-col
        gap-12;

    @screen laptop {
        @apply flex-row
            gap-21;
    }

    .column {
        @screen laptop {
            width: calc(100% / var(--columnsQuantity));
        }
    }
}
</style>
