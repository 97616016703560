import type { InvestmentData } from '@condo/sdk/propx';
import { defineStore, storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

export const useInvestmentsStore = defineStore('investments', () => {
    const investments = ref<InvestmentData[]>([]);

    function setInvestments(data: InvestmentData[]) {
        investments.value = data;
    }

    return { investments, setInvestments };
});

export const useSelectedInvestmentStore = defineStore('selectedInvestment', () => {
    const route = useRoute();
    const router = useRouter();
    const { selectedInvestment } = route.query;
    const investmentsStore = useInvestmentsStore();
    const { investments } = storeToRefs(investmentsStore);
    const initializeInvestorInfo = computed<InvestmentData>(() => {
        if (selectedInvestment) {
            const selectedInvestoment = investments.value.find(investment => investment.investorInfoId === Number(selectedInvestment));
            if (selectedInvestoment) {
                return selectedInvestoment;
            }
        }
        return investments.value[0];
    });

    const investment = ref<InvestmentData | null>(initializeInvestorInfo.value ?? null);

    function setInvestment(data: InvestmentData) {
        investment.value = data;
        router.push({ query: { selectedInvestment: data?.investorInfoId?.toString() } });
    }

    return { investment, setInvestment };
});
