<template>
    <n-collapse :default-expanded-names="[props.list[0]._uid]" accordion arrow-placement="right">
        <template #arrow>
            <i class="fa-regular fa-circle-minus circle-minus fa-lg"></i>
            <i class="fa-regular fa-circle-plus circle-plus fa-lg"></i>
        </template>
        <StoryblokComponent v-for="q in props.list" :key="q._uid" :blok="q" />
    </n-collapse>
</template>

<script lang="ts" setup>
const props = defineProps<{ title: string; list: any[] }>();
</script>

<style lang="scss">
.faq {
    .n-collapse-item {
        &__header-main {
            @apply justify-between
                gap-4;
        }

        &-arrow {
            @apply rotate-0 #{!important};
        }

        .circle-minus {
            @apply hidden;
        }

        &--active {
            .circle-minus {
                @apply block;
            }

            .circle-plus {
                @apply hidden;
            }
        }
    }
}
</style>
