import RequestError from '@/components/toasts/RequestError.vue';
import { auth0Client, getBearerToken } from '@/plugins/auth0';
import { initPropxHTTPClient } from '@condo/sdk/propx';
import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';
import type { AxiosInstance } from 'axios';
import { identity } from 'lodash-es';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import { useToast } from 'vue-toastification';
import { TYPE } from 'vue-toastification/src/ts/constants';
import type { IClientConfig } from '../types';

const toast = useToast();
export { propxApi as propx } from '@condo/sdk/propx';
export let api: AxiosInstance = null as any;

export const handleRequestError = async (error: any) => {
    switch (error?.response?.data?.statusCode) {
        case 401:
            // @todo: ensure logged out first by trying to force it first, to avoid infinite redirect loops
            await auth0Client.loginWithRedirect({
                authorizationParams: {
                    redirect_uri: `${window.location.origin}/dashboard`,
                    scope: 'openid profile email',
                },
                appState: { target: '/dashboard' },
            });
            break;
        default:
            toast(RequestError, { timeout: 15000, type: TYPE.ERROR });
    }
};

export const extendAxiosClient = (baseURL: string): AxiosInstance => {
    const client = axios.create({
        baseURL,
        paramsSerializer: {
            serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        },
    });

    client.interceptors.request.use(onRequest);
    client.interceptors.response.use(identity, onRejectedResponse);

    return client;
};

const onRequest = <T extends AxiosRequestConfig>(config: T): T | Promise<T> => {
    try {
        config.headers = config.headers ?? {};
        config.headers['x-stream-id'] = uuid();

        return getBearerToken().then(token => {
            if (token) {
                config.headers!.Authorization = `Bearer ${token}`;
            }
            return config;
        });
    } catch (_) {
        return config;
    }
};

const onRejectedResponse = async (error: any): Promise<any> => {
    if (!error?.config?.skipErrorHandler) {
        if ((error as AxiosError).isAxiosError) {
            const axiosError = error as any;
            if (axiosError.response?.data instanceof Blob && axiosError.response?.data.type === 'application/json') {
                console.error(error.message, JSON.parse(await axiosError.response.data.text()));
            } else {
                console.error(error.message, error.response?.data);
            }
        } else {
            console.error(error.message);
        }

        await handleRequestError(error);
    }

    return Promise.reject(error);
};

const initApi = (baseURL: string) => {
    api = extendAxiosClient(baseURL);
};

const initPropxApi = (baseURL: string) => {
    initPropxHTTPClient(baseURL, onRequest, onRejectedResponse);
};

export const initHttpClients = (config: IClientConfig) => {
    initApi(config.apiUrl);
    initPropxApi(config.propxUrl);
};
