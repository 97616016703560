<template>
    <n-form-item :class="showLabel ? '' : 'hide-label'" :label="showLabel ? props.title : ''">
        <n-upload
            :action="`${configStore?.config?.fileBinUrl}/api/file/stream`"
            :max="5"
            :path="path"
            :v-model="path"
            directory-dnd
            multiple
            response-type="json"
            @beforeUpload="handleBeforeUpload"
            @change="handleChange"
            @update:fileList="handleFileListChange"
        >
            <n-upload-dragger>
                <figure class="n-upload-dragger__icon">
                    <UploadIcon />
                </figure>
                <p class="n-upload-dragger__text">
                    <strong>{{ $t('uploadDocuments.attachments.title') }}</strong>
                    {{ $t('uploadDocuments.attachments.subTitle') }}
                </p>
            </n-upload-dragger>
        </n-upload>
    </n-form-item>
</template>

<script lang="ts" setup>
import { useConfigStore } from '@/store';

import { downscaleImageFiles } from '@condo/helpers';
import type { FileInfo } from 'naive-ui/es/upload/src/interface';

import { MAX_ALLOWED_UPLOAD_SIZE } from '../../../../constants/file';
import type { ContactRequestParams } from '../../../../domain/schemas';
import UploadIcon from '../../icons/UploadIcon.vue';

const configStore = useConfigStore();

const emit = defineEmits(['input']);

type Props = {
    title: string;
    placeholder: string;
    value: string;
    path: string;
    formData: Partial<ContactRequestParams>;
    showLabel: boolean;
};
const props = defineProps<Props>();

async function handleBeforeUpload({ file }: { file: File }) {
    if (file.type.includes('image')) {
        const downscaledImageFiles = await downscaleImageFiles(file?.file);
        if (downscaledImageFiles?.length > 0) {
            file.file = downscaledImageFiles[0];
        }
    }
    return true;
}

function handleChange({ file, event }: { file: FileInfo & { reason?: 'size' }; event?: ProgressEvent }) {
    // handling max file size
    if (file?.file?.size && file?.file?.size > MAX_ALLOWED_UPLOAD_SIZE) {
        file.status = 'error';
        file.reason = 'size';
    }
    const attachments = (event?.target as XMLHttpRequest)?.response;
    if (attachments) {
        const attachmentId = attachments[0].id;
        file.id = attachmentId;
    }
}
function handleFileListChange(newFileList: Array<FileInfo & { reason?: 'size' }>) {
    emit(
        'input',
        newFileList.map(file => ({
            id: file?.id,
            status: file?.status,
            reason: file?.reason,
        })),
    );
}
</script>

<style lang="scss" scoped>
.hide-label {
    @apply grid-rows-none #{!important};
}
.n-upload {
    &-file-input {
        @apply hidden #{!important};
    }

    &-dragger {
        @apply text-sm
            font-medium;

        &__icon {
            @apply mx-auto
                mb-5
                w-10
                rounded-full
                bg-light
                p-2.5
                text-primary;
        }

        &__text {
            strong {
                @apply block
                    font-semibold
                    text-primary;
            }
        }
    }
}
</style>
