<template>
    <figure :class="[`icon--${colorMapping[props.color]}`, sizeMapping[props.size].padding, sizeMapping[props.size].width, 'icon__wrapper']">
        <condo-icon :icon="props.icon" :size="sizeMapping[props.size].iconSize.toString()" icon-pack="fa-light" />
    </figure>
</template>

<script lang="ts" setup>
import { CondoIcon } from '@condo/ui';

/**
 * @see https://fontawesome.com/search?o=r&f=sharp
 */
const props = withDefaults(
    defineProps<{
        icon: string;
        size?: 'default' | 'xl';
        color?: 'light' | 'dark';
    }>(),
    { size: 'default', color: 'light' },
);

const sizeMapping = {
    default: {
        iconSize: 16,
        padding: 'p-1',
        width: 'w-10',
    },
    xl: {
        iconSize: 24,
        padding: 'p-2',
        width: 'w-14',
    },
};

const colorMapping = {
    light: 'light',
    dark: 'dark',
};
</script>

<style lang="scss" scoped>
.icon {
    &__wrapper {
        @apply rounded-full border-4 border-gray-50 transition-colors text-center;
    }

    &--light {
        @apply bg-gray-100
        text-primary;
    }

    &--dark {
        @apply bg-primary
        text-highlight;
    }
}
</style>
