<template>
    <section id="faq" class="faq">
        <h2>{{ props.title }}</h2>
        <h5>{{ props.subtitle }}</h5>
        <h4>{{ props.tabsTitle }}</h4>
        <div class="flex flex-col justify-between gap-8 laptop:flex-row tv:gap-16">
            <div class="laptop:w-1/3">
                <ul class="tab-button__wrapper">
                    <li
                        v-for="tab in tabs"
                        :key="tab?.name"
                        :class="{ 'tab-button': 1, 'tab-button--active': activeTab === tab?.name }"
                        :name="tab?.name"
                        @click="setActiveTab(tab?.name)"
                    >
                        <icon :color="activeTab === tab?.name ? 'dark' : 'light'" :icon="tab?.iconName" />
                        <em>{{ tab?.title }}</em>
                    </li>
                </ul>
            </div>
            <div class="laptop:w-2/3">
                <template v-for="tab in tabs" :key="tab?.name">
                    <storyblok-component v-if="activeTab === tab?.name" :key="tab?.blok._uid" :blok="tab?.blok" />
                </template>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import Icon from '@/components/Icon.vue';

type Props = {
    title: string;
    tabsTitle: string;
    subtitle: string;
    topics: {
        _uid: string;
        iconName: string;
        title: string;
        list: any[];
    }[];
};
const props = defineProps<Props>();

const activeTab = ref(props.topics[0]?._uid);
const tabs =
    props.topics?.map(topic => ({
        name: topic._uid,
        iconName: topic.iconName,
        title: topic.title,
        blok: topic,
    })) || [];

function setActiveTab(tabName: string) {
    activeTab.value = tabName;
}
</script>

<style lang="scss">
.faq {
    @apply container
        py-12;

    @screen laptop {
        @apply py-24;
    }

    h2 {
        @apply mb-6;
    }

    h5 {
        @apply mb-10
            text-black;
    }

    h4 {
        @apply mb-4
            font-medium;
    }
}
</style>

<style lang="scss" scoped>
.tab-button {
    @apply flex
        cursor-pointer
        items-center
        rounded-xl
        border
        border-gray-200
        px-3
        py-2.5
        transition-colors;

    &:hover {
        @apply border-primary;
    }

    &__wrapper {
        @apply grid
            gap-3;
    }

    em {
        @apply px-3
            text-lg;
    }

    &--active {
        @apply border-primary
            bg-gray-50
            font-medium
            text-blue-800;
    }
}
</style>
