<template>
    <n-card :key="props.title" class="post" @click="openModal">
        <template #cover>
            <img :src="props.previewImage.filename" />
        </template>
        <var>{{ intlDateFormat(props.publishedAt, false, 'short', 'numeric') }}</var>
        <h6>
            {{ props.title }}
            <span><i class="fa-regular fa-arrow-up-right fa-lg"></i></span>
        </h6>
        <p>{{ props.shortDescription }}</p>
        <n-tag v-for="(tag, index) in props.tags" :key="index" :bordered="false" class="mr-2" round size="small" type="primary">
            {{ tag }}
        </n-tag>
    </n-card>

    <n-modal v-model:show="showModal" :title="props.title" class="blog-modal" preset="card">
        <RichText :text="props.articleText" class="text" />
        <template #header-extra>
            <condo-icon class="cursor-pointer" icon="share-nodes" icon-pack="fa-light" size="18" @click="onShareClick" />
        </template>
        <template #footer>
            <n-tag v-for="(tag, index) in props.tags" :key="index" :bordered="false" class="mr-2" round size="small" type="primary">
                {{ tag }}
            </n-tag>
        </template>
    </n-modal>
</template>

<script lang="ts" setup>
import RichText from '@/components/RichText.vue';
import type { SbImage } from '@/components/StoryblokComponent.vue';
import type { ISbRichtext } from 'storyblok-js-client';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { intlDateFormat } from '@condo/formatters';
import { CondoIcon } from '@condo/ui';

interface Props {
    title: string;
    shortDescription: string;
    articleText: ISbRichtext;
    previewImage: SbImage;
    publishedAt: string;
    tags: any;
    slug: string;
}

const props = defineProps<Props>();
const showModal = ref(false);
const { t } = useI18n();
const router = useRouter();
const message = useMessage();

const onShareClick = async () => {
    try {
        await navigator.clipboard.writeText(
            `${window.location.origin}${
                router.resolve({
                    name: 'blog-post',
                    params: { slug: props.slug },
                }).href
            }`,
        );

        message.info(t('buttons.copiedToClipboard'), { duration: 1200 });
    } catch (err) {
        console.error('Failed to write to clipboard: ', err);
    }
};

const openModal = () => {
    showModal.value = true;
};
</script>

<style lang="scss">
.post {
    @apply cursor-pointer;

    &__list {
        @apply my-12
            grid
            gap-8;

        @screen tablet {
            @apply grid-cols-2;
        }

        @screen tv {
            @apply my-16
                grid-cols-3;
        }
    }

    .n-card {
        &__content {
            @apply mt-6;

            p {
                @apply mb-4
                    mt-2;

                @screen laptop {
                    @apply text-lg;
                }
            }
        }
    }

    var {
        @apply mb-2
            block
            text-sm
            font-semibold
            text-blue-700;
    }

    h6 {
        @apply flex
            justify-between
            font-semibold
            text-gray-900;
    }
}
.blog-modal {
    @apply w-full tablet:w-4/6 tablet:mt-5 #{!important};
    @apply tablet:px-8;

    @import '@/styles/static-content-typography';

    p {
        @apply leading-normal pb-3;
    }

    ol {
        @apply list-decimal ml-4;
    }

    ul {
        @apply list-disc ml-4;
    }
}
</style>
