<template>
    <div v-if="multiOption || props.showSingle">
        <template v-if="!props.hideTitle">{{ $t('selectInvestment') }}</template>
        <n-select
            :default-value="defaultValue"
            :options="estatesOptions"
            :placeholder="$t('buttons.selectEstate')"
            :value="selectedInvestmentStore.investment.investorInfoId"
            @update:value="onUpdate"
        />
    </div>
</template>

<script lang="ts" setup>
import { useInvestmentsStore, useSelectedInvestmentStore } from '@/store';
import { addressFormatterWithFloor } from '@condo/formatters';

interface Props {
    showSingle?: boolean;
    hideTitle?: boolean;
}

const props = defineProps<Props>();
const investmentsStore = useInvestmentsStore();
const selectedInvestmentStore = useSelectedInvestmentStore();

const estatesOptions = computed(() => {
    if (investmentsStore.investments.values.length) {
        return [];
    }

    return investmentsStore.investments
        .map(({ estate: { location }, investorInfoId }) => ({
            label: addressFormatterWithFloor(location, {
                floor: location.floor,
                floorLocation: location.floorLocation,
            }),
            value: investorInfoId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
});

const defaultValue = estatesOptions.value[0]?.value;
const multiOption = estatesOptions.value.length > 1;

function onUpdate(value: number) {
    selectedInvestmentStore.setInvestment(investmentsStore.investments.find(({ investorInfoId }) => investorInfoId === value));
}
</script>

<style lang="scss" scoped></style>
